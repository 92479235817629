import React from 'react';
import ReactDOM from 'react-dom/client';
import './theme/reset.sass';
import './theme/theme.sass';
import {createHashRouter, RouterProvider} from "react-router-dom";
import routes from "./routes";
import UsersStore from "./store/user.store";
import MessagesStore from "./store/message.stor";
import LocationsStore from "./store/location.stor";
import StaticStore from "./store/static.store";
import LangsStore from "./store/langs.store";
import ConfigStore from "./store/config.store";

export const Context = React.createContext(null);

function renderReactDom() {
    const root = ReactDOM.createRoot(document.getElementById('root'));
    const router = createHashRouter(routes, {
        future: {
            v7_normalizeFormMethod: true,
        },
    });
    root.render(
        <Context.Provider value={
            {
                user_store: new UsersStore(),
                message_store: new MessagesStore(),
                location_store: new LocationsStore(),
                static_store: new StaticStore(),
                lang_store: new LangsStore(),
                config_store: new ConfigStore(),
            }
        }>
            <RouterProvider router={router} />
        </Context.Provider>
    )
}

if (window.cordova) {
    document.addEventListener('deviceready', () => {
        renderReactDom();
    }, false);
} else {
    renderReactDom();
}