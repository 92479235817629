import React from 'react';
import Avatar from "../ui/avatar/avatar";
import Notification from "../ui/notification/notification";
import Group from "../ui/group/group";

const HeaderComponent = ({termo, img, name, hi, description, clickAvatar, clickNotification, countMessages}) => {

    const [t, setT] = React.useState([]);
     React.useEffect(() => {
         if (termo && Object.keys(termo).length > 0) {
             setT(() => termo)
         }
     }, [termo])

    return (
        <Group variant={'horizontal'} style={{alignItems: 'center'}}>
            <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <Avatar img={img} name={name} hi={hi} description={description} onClick={clickAvatar}/>

                {
                    clickNotification
                    && <Notification onClick={clickNotification} count={countMessages}/>
                }
            </div>
            <div style={{width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
                {
                    t.length > 0
                    &&
                    <>
                        <p style={{fontSize: '.8rem', color: 'grey'}}>{(t?.at(0)?.value * 0.1).toFixed(1)} ℃</p>
                        <p style={{fontSize: '.8rem', color: 'grey'}}>{(t?.at(1)?.value).toFixed(1)} %</p>
                    </>
                }
            </div>
        </Group>
    );
};

export default HeaderComponent;