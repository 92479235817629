import React from 'react';
import VersionComponent from "../components/version.component";
import AuthComponent from "../components/auth.component";
import RegistrationComponent from "../components/registration.component";
import {observer} from "mobx-react-lite";
import useAuth from "../hooks/useAuth";
import {Context} from "../index";
import {useNavigate} from "react-router-dom";
import Loader from "../ui/loader/loader";
import ConfigMethods from "../methods/config.methods";
import UpdateComponent from "../components/update.component";

const AuthPage = () => {
    const {user_store, config_store} = React.useContext(Context)
    const {user, loading, setLoading} = useAuth();
    const [update, setUpdate] = React.useState(false);
    const [updateLink, setUpdateLink] = React.useState('');
    const navigate = useNavigate()

    React.useEffect(()  =>  {
        ConfigMethods.getConfig().then((data)  =>  {
            config_store.setConfig(data.data);
        });
    }, []);

    React.useEffect(() =>  {
        if (!localStorage.getItem('lang')) {
            localStorage.setItem('lang', 'lv');
        }
    }, [])

    React.useEffect(() => {
        if (user) {
            user_store.setIsAuth(true);
            setLoading(() => false);
            navigate('/');
        } else {
            user_store.setIsAuth(false);
            setLoading(() => false);
        }
    }, [user, navigate, setLoading, user_store]);

    const [registration, setRegistration]  =  React.useState(false);

    React.useEffect(()  =>  {
        if (window.cordova) {
            // alert(window.device.platform)
            if (window.device.platform === 'Android') {
                window.cordova.getAppVersion.getVersionNumber(function (version) {
                    if (config_store.isConfig.android_version && version !==  config_store.isConfig.android_version)  {
                        setUpdate(() => true);
                        setUpdateLink(() => config_store.isConfig.android_update_url);
                    }
                });
            }
            else if (window.device.platform === 'iOs') {
                window.cordova.getAppVersion.getVersionNumber(function (version) {
                    if (config_store.isConfig.ios_version && version !==  config_store.isConfig.ios_version)  {
                        setUpdate(() => true);
                        setUpdateLink(() => config_store.isConfig.ios_update_url);
                    }
                });
            }
        } else {
            // alert('cordova not found');
            if (config_store.isConfig.android_version && String(config_store.isConfig.android_version) !== '1.0.1') {
                setUpdate(() => true);
                setUpdateLink(() => config_store.isConfig.android_update_url);
            }
        }
    }, [config_store, config_store.isConfig]);


    if (loading) {
        return <div style={{height: '100vh', width: '100vw', display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
            <Loader />
        </div>
    }

    return (
        <>
            {
                !update
                    ?
                    <>
                        <VersionComponent />
                        {
                            registration
                                ?
                                <RegistrationComponent
                                    toAuth={
                                        () => setRegistration(()  => false)
                                    }
                                />
                                :
                                <AuthComponent
                                    toRegistration={
                                        () => setRegistration(() => true)
                                    }
                                />

                        }
                    </>
                    :
                    <UpdateComponent update_link={updateLink} />

            }


        </>
    );
};

export default observer(AuthPage);