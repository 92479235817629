import React from 'react';
import Button from "../ui/button/button";
import ButtonBlock from "../ui/button/buttonBlock";
import useLang from "../hooks/useLang";
import LanguageMethods from "../methods/language.methods";
import {observer} from "mobx-react-lite";
import StaticMethods from "../methods/static.methods";
import {Context} from "../index";

const LangSwitcherComponent = () => {
    const {static_store} = React.useContext(Context);
    const {lang, onChange} = useLang(localStorage.getItem("lang"))
    const [langs, setLangs] = React.useState([])

    React.useEffect(()  =>  {
        LanguageMethods.getLanguages().then(res =>  {
            setLangs(() => Object.values(res.data))
        })
    }, [])

    const switchLang = (isoCode)  =>  {
        onChange(isoCode);
        localStorage.setItem("lang", isoCode);
        static_store.setLoading(true)
        StaticMethods.getStaticByLang(isoCode).then(res=> {
            static_store.setStatics(res.data)
        }).finally(()  => {
            static_store.setLoading(false)
            window.location.reload();
        })
    }

    return (
        <ButtonBlock>
            {
                langs.slice().sort((a, b) => b.isoCode - a.isoCode ? 1 : -1).map((l, index) => {
                    return (
                        <Button active={lang === l.isoCode} onClick={() => switchLang(l.isoCode)} variant={'lang'} key={index}>{l.isoCode}</Button>
                    );
                })
            }
        </ButtonBlock>
    );
};

export default observer(LangSwitcherComponent);