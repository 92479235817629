import {$host} from "../http/axios";

export default class LocationMethods {
    static addLocation = async (formData) => {
        const {data} = await $host.post(`/location/add`, formData);
        return data;
    }

    static getLocations = async () => {
        const {data} = await $host.post(`/location/all?limit=1000`);
        return data;
    }

    static getLocationById = async (formData) => {
        const {data} = await $host.post(`/location/get/${formData}`);
        return data;
    }
    static getLocationByName = async (formData) => {
        const {data} = await $host.post(`/location/get/one`, formData);
        return data;
    }

    static updateLocationById = async (formData) => {
        const {data} = await $host.post(`/location/update/${formData}`);
        return data;
    }

    static deleteLocationById = async (id) => {
        const {data} = await $host.post(`/location/delete/${id}`);
        return data;
    }
}