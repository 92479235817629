import React from "react";
import StaticMethods from "../methods/static.methods";
import {observer} from "mobx-react-lite";
import {Context} from "../index";


const T = ({children}) => {
    const {static_store} = React.useContext(Context);
    const [translate, setTranslate] = React.useState(children);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if (Object.keys(static_store.isStatics).length !== 0) {
            if (children && !static_store.isStatics[children]){
                setTranslate(children);
                const formData = new FormData();
                formData.append('field', children);
                setTimeout(() => {
                    StaticMethods.addStatic(formData).then()
                }, 100);
            } else {
                setTranslate(static_store.isStatics[children]);
            }
            setLoading(false)
        }
    }, [children, static_store.isStatics]);


    return (
        loading
            ?
                <></>
            :
                translate
    );
};

export default observer(T);
