import React from 'react';
import Sider from "../ui/sider/sider";
import Typography from "../ui/typography/typography";
import Group from "../ui/group/group";
import Input from "../ui/input/input";
import Button from "../ui/button/button";
import useInput from "../hooks/useInput";
import ButtonBlock from "../ui/button/buttonBlock";
import {useNavigate} from "react-router-dom";
import useEmail from "../hooks/useEmail";
import {UserMethods} from "../methods/user.methods";
import Loader from "../ui/loader/loader";
import Alert from "../ui/alert/alert";
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import T from "./t";
import StaticMethods from "../methods/static.methods";
import {jwtDecode} from "jwt-decode";

const startTime = 10;

const AuthComponent = ({toRegistration}) => {
    const {user_store, static_store} = React.useContext(Context);
    const [step, setStep]  =  React.useState(1);
    const [time, setTime]  =  React.useState(startTime);
    const [loading, setLoading]  =  React.useState(true);
    const navigate = useNavigate();
    const {isEmail, changeEmail} = useEmail();
    const [error, setError]  =  React.useState('');

    const email = useInput("");
    const code1 = useInput("");

    React.useEffect(() =>  {
        static_store.setLoading(true);
        StaticMethods.getStaticByLang(localStorage.getItem('lang')).then((res)  =>  {
            static_store.setStatics(res.data);
        }).finally(() =>  static_store.setLoading(false));

    }, [static_store])

    React.useEffect(()  =>  {
        if (email.value.length  > 4) {
            changeEmail(email.value);
        }
    }, [email.value, changeEmail]);

    React.useEffect(() => {
        if (error.length > 0)  {
            setTimeout(()  => {
                setError(() => '');
            }, 3000);
        }
    }, [error]);

    const sendEmail = (email) => {
        setLoading(() => true);
        const formData  = new FormData();
        formData.append("email", email);
        UserMethods.login(formData).then((res)  =>  {
            setStep(() => 2);
        }).catch((err) => {
            setError(() => err.response.data.errors[0])
        }).finally(() => {
            setLoading(() => false);
        });
    }

    const sendCode = (code) => {
        setLoading(() => true);
        const formData  = new FormData();
        formData.append("email", email.value);
        formData.append("confirmCode", code);
        UserMethods.confirm(formData).then((res)  =>  {
            if (String(jwtDecode(res.data).roleValue) !== "999") {
                setError(() => 'Это приложение только для арендаторов');
                setTimeout(()  => {
                    window.location.reload();
                }, 3000)
            } else {
                localStorage.setItem("token",  res.data);
                user_store.setIsAuth(true);
                navigate('/')
            }

        }).catch((err) => {
            setError(() => err.response.data.errors[0])
        }).finally(() => {
            setLoading(() => false);
        });
    }

    React.useEffect(() =>  {
        setLoading(() => false);
    }, []);

    React.useEffect(() => {
        if (step === 2) {
            const timeout = setTimeout(() => {
                setTime(() => time - 1);
            }, 1000)
            if (time === 0) {
                clearTimeout(timeout);
            }

        }
    }, [time, step])

    const back = () => {
        setTime(() => startTime);
        setStep(() => 1);
    }

    if (loading || static_store.isLoading)  {
        return <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
            <Loader />
        </div>
    }

    return (
        step === 1
            ?
                <>
                    {
                        error.length > 0
                        &&
                        <Alert variant={"error"}>
                            {error}
                        </Alert>
                    }
                    <Sider active={!loading} position={"bottom"}>
                        <Typography variant={"h3"}>
                            <T>Авторизация</T>
                        </Typography>
                        <Group variant={"horizontal"}>
                            <Input type={"email"} label={<T>Email</T>} {...email}/>
                            <Button disabled={
                                email.error ||
                                email.value.length === 0 ||
                                !isEmail
                            } onClick={() => sendEmail(email.value)}><T>Далее</T></Button>
                        </Group>
                        <Typography>
                            <T>Введите ваш Email и нажмите далее</T>
                        </Typography>
                        <ButtonBlock>
                            <Typography>
                                <T>Нет аккаунта?</T>
                            </Typography>
                            <Typography onClick={toRegistration} variant={"link"}>
                                <T>Зарегистрируйтесь</T>
                            </Typography>
                        </ButtonBlock>
                    </Sider>
                </>

            :

                <>
                    {
                        error.length > 0
                        &&
                        <Alert variant={"error"}>
                            <T>{error}</T>
                        </Alert>
                    }


                    <Sider active={!loading} position={"bottom"}>
                        <Typography variant={"h3"}>
                            <T>Авторизация</T>
                        </Typography>
                        <Group variant={"horizontal"}>
                            <Input type={"number"} label={<T>Код</T>} {...code1} />
                            <Button disabled={code1.error || code1.value.length < 6} onClick={() => sendCode(code1.value)}><T>Войти</T></Button>
                        </Group>
                        {
                            time === 0
                                ?
                                <Typography style={{marginTop: '1rem'}} variant={"link"} onClick={back}>
                                    <T>Повторно запросить код</T>
                                </Typography>
                                :
                                <Typography>
                                    <T>Повторно запросить код через</T>: {time} <T>сек</T>
                                </Typography>
                        }
                        <ButtonBlock>
                            <Typography>
                                <T>Нет аккаунта?</T>
                            </Typography>
                            <Typography onClick={toRegistration} variant={"link"}>
                                <T>Зарегистрируйтесь</T>
                            </Typography>
                        </ButtonBlock>
                    </Sider>
                </>

    );
};

export default observer(AuthComponent);