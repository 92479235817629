import React from 'react';
import module from './button.module.sass';

const ButtonBlock = ({children}) => {
    return (
        <div className={module.buttonBlock}>
            {children}
        </div>
    );
};

export default ButtonBlock;