import React from 'react';
import module from './notification.module.sass';
import RING from '../../icons/ring.svg';

const Notification = ({count, onClick}) => {
    return (
        <div className={module.notification} onClick={onClick}>
            {count > 0 && <span>{count}</span>}
            <img src={RING} alt="notification" />
        </div>
    );
};

export default Notification;