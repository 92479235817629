import React from 'react';
import module from './accordion.module.sass';

const accordionVariant = {
    menu: 'menu',
    contacts: 'contacts',
    message: 'message',
}

const Accordion = ({style, variant, children}) => {
    switch (variant) {
        case  accordionVariant.menu:
            return (
                <div style={style} className={module.accordionMenu}>
                    {children}
                </div>
            )
        case  accordionVariant.contacts:
            return (
                <div style={style} className={module.accordionContacts}>
                    {children}
                </div>
            )
        case  accordionVariant.message:
            return (
                <div style={style} className={module.accordionMessage}>
                    {children}
                </div>
            )
        default:
            return (
                <div className={module.accordion}>
                    {children}
                </div>
            );
    }


};

export default Accordion;