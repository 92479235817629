import React from "react";

const useOuterClick = (reference, func) => {
    React.useEffect(() => {
        const onClick = e => reference.current.contains(e.target) || func(() => false);
        document.addEventListener('click', onClick);
        return () => document.removeEventListener('click', onClick);

    }, [func, reference]);
}

export default useOuterClick;