import React from 'react';

const regEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const useEmail = () => {
    const [isEmail, setIsEmail] = React.useState(false);
    const changeEmail = (initial) => {
        const res = regEmail.test(initial);
        setIsEmail(() => res);
    }

    return {
        isEmail,
        changeEmail,
    };
}

export default useEmail;