import React from 'react';
import Wrapper from "../ui/wrapper/wrapper";
import HeaderComponent from "../components/header.component";
import Typography from "../ui/typography/typography";
import Sider from "../ui/sider/sider";
import Accordion from "../ui/accordion/accordion";
import AccordionItem from "../ui/accordion/accordionItem";
import BACK from "../icons/back.svg";
import MESSAGE from "../icons/message.svg";
import {useNavigate} from "react-router-dom";
import {Context} from "../index";
import useAuth from "../hooks/useAuth";
import {observer} from "mobx-react-lite";
import MessageMethods from "../methods/message.methods";
import Loader from "../ui/loader/loader";
import parse from 'html-react-parser';
import Group from "../ui/group/group";
import T from "../components/t";

const MessagesPage = () => {
    const {user_store, message_store} = React.useContext(Context)
    const {user, loading, setLoading} = useAuth();
    const navigate = useNavigate();
    const [messages, setMessages] = React.useState([]);

    React.useEffect(() => {
        if (user) {
            setLoading(() => true)
            user_store.setIsAuth(true);
            MessageMethods.getMessages().then((data)  =>  {
                message_store.setMessages(data);
                if (data.count !== 0)   {
                    Object.values(data.data).map((d)  => {
                        MessageMethods.getMessage(d.id).then((message) => {
                            setMessages((prev) => [...prev, message.data]);
                        })
                    })
                }
            }).finally(() => setLoading(() => false));

        } else {
            user_store.setIsAuth(false);
        }
    }, [user, navigate, user_store, message_store]);

    const readTheMessage = (id)  =>  {
        MessageMethods.readMessages(id).then((message)  =>  {
            setMessages((prev) => prev.map((m) => {
                if (m.id === message.data.id) {
                    return {...m, isRead: true};
                }
                return m;
            }))
        })
    }


    if (loading) {
        return <div style={{height: '100vh', width: '100vw', display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
            <Loader />
        </div>
    }

    return (
        <Wrapper style={{paddingTop: "7rem"}}>
            <Sider>
                <HeaderComponent  img={BACK} description={<T>Сообщения</T>} clickAvatar={() => navigate("/")}/>
            </Sider>
            <Accordion variant={"message"}>
                {
                    messages.length > 0 && messages.slice().sort((a, b)  =>  new Date(b.createdAt) - new Date(a.createdAt)).map((data) => {
                        return (
                            <AccordionItem getData={() =>!data.isRead && readTheMessage(data.id)} read={!data.isRead} key={data.id} icon={MESSAGE} title={data.theme}>
                                <Typography>{data.message && parse(data.message)}</Typography>
                                <Group style={{marginTop:  "1rem"}}>
                                    <Typography style={{fontSize: '.8rem'}}><T>Дата</T></Typography>
                                    <Typography style={{fontSize: '.8rem'}}>{data.createdAt.split("T")[0].replaceAll("-",".")}</Typography>
                                </Group>

                            </AccordionItem>
                        );
                    })
                }
            </Accordion>

        </Wrapper>
    );
};

export default observer(MessagesPage);