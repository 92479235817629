import React from 'react';
import Wrapper from "../ui/wrapper/wrapper";
import HeaderComponent from "../components/header.component";
import Sider from "../ui/sider/sider";
import BACK from "../icons/back.svg";
import {useNavigate} from "react-router-dom";
import Input from "../ui/input/input";
import Button from "../ui/button/button";
import Group from "../ui/group/group";
import useInput from "../hooks/useInput";
import Select from "../ui/select/select";
import {phoneCodes} from "../phoneCodes";
import {Context} from "../index";
import useAuth from "../hooks/useAuth";
import {observer} from "mobx-react-lite";
import {UserMethods} from "../methods/user.methods";
import Loader from "../ui/loader/loader";
import {jwtDecode} from "jwt-decode";
import Alert from "../ui/alert/alert";
import T from "../components/t";


const ProfilePage = () => {
    const {user_store} = React.useContext(Context);
    const {user} = useAuth();
    const navigate = useNavigate();
    const [loader, setLoader]  = React.useState(true);
    const [profile, setProfile]  = React.useState({});

    const firstName = useInput(user_store.isProfile.first_name);
    const lastName = useInput(user_store.isProfile.last_name);
    const email = useInput(user_store.isProfile.email);
    const [phoneCode, setPhoneCode] = React.useState(user_store.isProfile.phone_code);
    const [success, setSuccess] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState('');
    const phone = useInput(user_store.isProfile.phone);

    React.useEffect(() => {
        if (user) {
            user_store.setIsAuth(true);
        } else {
            user_store.setIsAuth(false);
        }
    }, [user, navigate, user_store]);

    React.useEffect(()  =>  {
        UserMethods.getProfile().then((res)  =>  {
            user_store.setProfile(res.data);
            setProfile(res.data);
        }).finally(()  =>  {
            setLoader(() => false);
            setLoading(false)
        })
    }, [user_store]);

    const changePhoneCode = (e) => {
        setPhoneCode(() => e);
    }

    const sendForm = (firstName, lastName, phone) => {
        const formData = new FormData();

        const {id, roleId} = jwtDecode(localStorage.getItem('token'));
        formData.append('first_name', firstName);
        formData.append('last_name', lastName);
        formData.append('phone_code', phoneCode);
        formData.append('phone', phone);
        formData.append('roleId', roleId);
        formData.append('isActive', true);
        UserMethods.updateUser(id, formData)
            .then(() => {
                setSuccess('Профиль успешно обновлен');
            })
            .catch(error => {
                if (error.response.data.errors.length > 0){
                    error.response.data.errors.map(err => {
                        setError(error.response.data.message);
                    })
                } else {
                    setError(error.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false)
                setLoader(() => false);
            });
    }

    React.useEffect(()  =>  {
        if (success.length > 0) {
            setTimeout(()  =>  {
                setSuccess('');
                navigate("/");
            }, 3000)
        }
        if (error.length > 0) {
            setTimeout(()  =>  {
                setError('');
            }, 3000)
        }
    }, [success, error]);

    if (loader || loading) {
        return (<div style={{height: '100vh', width: '100vw', display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
            <Loader />
        </div>)
    }

    return (
        <Wrapper>
            {
                success.length > 0 && <Alert variant={"success"}><T>{success}</T></Alert>
            }
            {
                error.length > 0 &&  <Alert variant={"error"}>{error}</Alert>
            }
            <Sider>
                <HeaderComponent img={BACK} description={<T>Редактировать профиль</T>}
                                 clickAvatar={() => navigate("/")}/>
                {/*<div className={"profile-page"}>*/}
                {/*    <img className={"profile-page-img"} src={DEFAULT} alt={"Профиль"}/>*/}
                {/*    <Button>Изменить</Button>*/}
                {/*</div>*/}
            </Sider>
            <Group variant={"horizontal"}>
                <Input type={"text"} label={<T>Имя</T>} {...firstName} />
                <Input type={"text"} label={<T>Фамилия</T>} {...lastName} />
                <Group>
                    <Select
                        searchOn
                        defaultValue={profile.phone_code}
                        label={<T>Регион</T>}
                        onRes={changePhoneCode}
                        {...phoneCode}
                        options={phoneCodes}
                    />
                    <Input type={"number"} label={<T>Телефон</T>} {...phone} />
                </Group>
                <Input disabled type={"email"} label={<T>Email</T>} {...email} />

                <Button
                    // disabled={
                    //     firstName.error || firstName.value.length === 0 ||
                    //     lastName.error || lastName.value.length === 0 ||
                    //     email.error || email.value.length === 0 ||
                    //     phoneCode.error || phoneCode.value.length === 0 ||
                    //     phone.error || phone.value.length === 0
                    // }
                    onClick={
                        () => sendForm(
                            firstName.value,
                            lastName.value,
                            phone.value
                        )
                    }
                >
                    <T>Сохранить</T>
                </Button>
            </Group>
        </Wrapper>
    );
};

export default observer(ProfilePage);