import React from 'react';
import module from './input.module.sass';

const Input = ({type, label, value, onChange, onBlur, disabled}) => {
    return (
        <label className={module.label}>
            {label && <span>{label}</span>}
            <input
                className={module.input}
                type={type}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                disabled={disabled}/>
        </label>

    );
};

export default Input;