import React from 'react';
import SiderContentComponent from "./siderContent.component";
import Sider from "../ui/sider/sider";

const SiderComponent = ({active, pages, setActive}) => {
    const [show, setShow]  = React.useState(false);

    React.useEffect(() => {
        setShow(() => active);
    }, [active]);

    const rootEl = React.useRef(null);

    const toggleLeftSider  = () => {
        setShow(() => false);
        setActive(() => false);
    }

    return (
        <Sider ref={rootEl} active={show ? show : active} position={"left"}>
            <SiderContentComponent pages={pages} closeSider={toggleLeftSider} />
        </Sider>
    );
};

export default SiderComponent;