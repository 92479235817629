import React from 'react';
import Typography from "../ui/typography/typography";
import T from "./t";
import LangSwitcherComponent from "./langSwitcher.component";
import {observer} from "mobx-react-lite";
import Button from "../ui/button/button";
import Loader from "../ui/loader/loader";
import {Context} from "../index";

const UpdateComponent = ({update_link}) => {
    const {static_store} = React.useContext(Context);

    if (static_store.isLoading)  {
        return <div style={{height: '100vh', display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
            <Loader />
        </div>
    }

    const update = () => {
        window.open(update_link, '_blank');
    }

    return (
        <div className={"notActive"}>
            <Typography variant={"h1"}>
                !
            </Typography>
            <div style={{marginBottom: '3rem'}}>
                <LangSwitcherComponent/>
            </div>

            <Typography variant={"h4"}>
                <T>Вашe приложение устарело</T>
            </Typography>
            <Typography>
                <T>Обновите приложение</T>
            </Typography>
            <Button onClick={update} style={{marginTop:  '4rem'}}>
                <T>Обновить</T>
            </Button>
        </div>
    );
};

export default observer(UpdateComponent);