import React from 'react';
import module from './select.module.sass';
import Typography from "../typography/typography";
import CHEVRON from "../../icons/chevron.svg";
import NOTDATA from "../../icons/notData.svg";
import useOuterClick from "../../hooks/useOuterClick";
import T from "../../components/t";

const selectVariant = {
    text: "text",
    multiply: "multiply",
}

const Select = ({variant, label, options, defaultValue, onRes, searchOn}) => {
    const [select, setSelect] = React.useState(defaultValue || "");
    const [open, setOpen] = React.useState(false);
    const [opt, setOpt] = React.useState(options);
    const [changed, setChanged] = React.useState(false);

    const rootEl = React.useRef(null);

    useOuterClick(rootEl, setOpen)

    const toggleSelect = () => {
        setOpen((prevState) => !prevState);
    }

    const changeSelect = (value) => {
        setSelect(() => value);
        onRes(value);
        toggleSelect()
        setChanged(() => false);
    }

    React.useEffect(()  =>  {
        if (changed)  {
            setOpt(() => options.filter((option) => String(option.label).toLowerCase().includes(String(select.toLowerCase()))));
        } else {
            options.filter((option) => String(option.label).toLowerCase().includes(String(select.toLowerCase())))
        }
    }, [options, select, changed]);

    const changeSearch  = (e)  => {
        setSelect(()  =>  e.target.value);
        setChanged(() => true)
    }

    switch (variant)  {
        case selectVariant.text:
            return <label className={module.text} ref={rootEl}>
                {label && <span>{label}</span>}
                <div className={open ? module.value + ' ' + module.open : module.value} onClick={toggleSelect}>
                    <Typography variant={"h3"}>
                        {select}
                    </Typography>
                    <img onClick={toggleSelect} src={CHEVRON} alt={"select-icon"}/>
                </div>
                {
                    options && options.length > 0
                    &&
                    <div className={open ? module.options + ' ' + module.open : module.options}>
                        {options.map((option) => {
                            return <div
                                className={option.value === select ? module.option + ' ' + module.active : module.option}
                                key={option.value} onClick={() => changeSelect(option.value)}>{option.label}</div>
                        })}
                    </div>
                }

            </label>
        case selectVariant.multiply:
            return <label className={module.multiply}>
                <div className={module.options}>
                    {options.map((option, index) => {
                        return <div key={index}>{option}</div>
                    })}
                </div>
            </label>
        default:
            return <label className={module.button} ref={rootEl} onClick={toggleSelect}>
                {label && <span>{label}</span>}
                <div className={open ? module.value + ' ' + module.open : module.value}>
                    {
                        searchOn
                        ?
                            <input value={select} onBlur={() => setChanged(() => false)} onChange={(e) => changeSearch(e)}/>
                        :
                            <Typography variant={"h3"}>
                                {select}
                            </Typography>
                    }

                    <img onClick={toggleSelect} src={CHEVRON} alt={"select-icon"}/>
                </div>
                {
                    opt && opt.length > 0
                        ?
                        <div className={open ? module.options + ' ' + module.open : module.options}>
                            {opt.map((option) => {
                                return <div
                                    className={option.value === select ? module.option + ' ' + module.active : module.option}
                                    key={option.id} onClick={() => changeSelect(option.value)}>{option.label}</div>
                            })}
                        </div>
                        :
                        <div className={open ? module.options + ' ' + module.open : module.options}>
                            <div className={module.notData}>
                                <img src={NOTDATA} alt={"select-icon"}/>
                                <Typography><T>Нет данных</T></Typography>
                            </div>
                        </div>

                }
            </label>
    }
};

export default Select;