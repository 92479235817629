import React from 'react';
import LOGO from '../icons/ambar.svg';
import Typography from "../ui/typography/typography";
import Centered from "../ui/centerd/centered";
import LangSwitcherComponent from "./langSwitcher.component";
import {observer} from "mobx-react-lite";
import {Context} from "../index";

const VersionComponent = () => {
    const {config_store} = React.useContext(Context)

    const [app_version, setAppVersion] = React.useState("");

    React.useEffect(() => {
        if (window.cordova) {
            if (window.device.platform === 'Android' || window.device.platform === 'iOS') {
                window.cordova.getAppVersion.getVersionNumber(function (version) {
                    setAppVersion(() => version)
                });
            }
        }
    }, [config_store])

    return (
        <Centered>
            <img src={LOGO} alt={'Ambar App'} />
            <Typography variant={"h4"}>
                Ambar App
            </Typography>
            <Typography>
                {app_version.length > 0 ? app_version : '1.0.0'}
            </Typography>
            <LangSwitcherComponent/>
        </Centered>
    );
};

export default observer(VersionComponent);