import React from 'react';
import module from './logout.module.sass';
import Typography from "../typography/typography";
import LOGOUT from '../../icons/logout.svg';

const Logout = ({onClick, style, description}) => {
    return (
        <div style={style} className={module.logout} onClick={onClick}>
            <div className={module.logoutImg}>
                <img src={LOGOUT} alt={'logout'}/>
            </div>
            <Typography variant={'h5'}>
                {description}
            </Typography>
        </div>
    );
};

export default Logout;