import {makeAutoObservable} from "mobx";

export default class LocationsStore{
    constructor() {
        this._locations = {};
        makeAutoObservable(this);
    }

    setLocations(data) {this._locations = data}

    get isLocations() {return this._locations}

}