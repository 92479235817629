import React from 'react';
import Typography from "../ui/typography/typography";
import T from "./t";
import Accordion from "../ui/accordion/accordion";
import AccordionItem from "../ui/accordion/accordionItem";
import EMAIL from "../icons/email.svg";
import PHONE from "../icons/phone.svg";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import Content from "../ui/content/content";
import Loader from "../ui/loader/loader";
import LangSwitcherComponent from "./langSwitcher.component";
import ConfigMethods from "../methods/config.methods";

const NotActiveComponent = () => {
    const {config_store, static_store} = React.useContext(Context);
    const [email, setEmail]  = React.useState("");
    const [phone, setPhone]  = React.useState("");

    React.useEffect(() =>  {
        ConfigMethods.getConfig().then((res) =>  {
            config_store.setConfig(res.data);
        });
    }, []);

    React.useEffect(() => {
        setEmail(() => config_store.isConfig.email);
        setPhone(() => config_store.isConfig.phone);
    }, [config_store, config_store.isConfig]);

    if (static_store.isLoading)  {
        return <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
            <Loader />
        </div>
    }

    return (
        <div className={"notActive"}>
            <Typography variant={"h1"}>
                !
            </Typography>
            <div style={{marginBottom: '3rem'}}>
                <LangSwitcherComponent/>
            </div>
            <Typography variant={"h4"}>
                <T>Ваш аккаунт не активирован</T>
            </Typography>
            <Typography>
                <T>Администратор активирует аккаунт после проверки</T>
            </Typography>
            <Content style={{marginTop: "3rem"}}>
                <Accordion variant={"contacts"}>
                    <AccordionItem icon={EMAIL} title={<><p><T>Email</T>:</p> <strong><a
                        href={`mailto:${email}`}>{email}</a></strong></>}/>
                    <AccordionItem icon={PHONE} title={<><p><T>Телефон</T>:</p> <strong><a
                        href={`tel:${phone}`}>{phone}</a></strong></>}/>
                </Accordion>
            </Content>
        </div>
    );
};

export default observer(NotActiveComponent);