import React from 'react';
import module from './bigAvatar.module.sass';
import Typography from "../typography/typography";
import {useNavigate} from "react-router-dom";
import T from "../../components/t";
import {observer} from "mobx-react-lite";

const BigAvatar = ({name, img}) => {
    const navigate = useNavigate();

    return (
        <div className={module.bigAvatar}>
            {
                img
                    ?
                    <img className={module.bigAvatarImg} src={img} alt={name}/>
                    :
                    <div className={module.bigAvatarName}>
                        {name.split(' ')[0].at(0)} {name.split(' ')[1].at(0)}
                    </div>
            }
            <div className={module.bigAvatarText}>
                <Typography variant={"h4"}>
                    {name}
                </Typography>
            </div>
            <div onClick={() => navigate('/profile')} className={module.bigAvatarLink}>
                <Typography variant={"link"}>
                    <T>Редактировать</T>
                </Typography>
            </div>
        </div>
    );
};

export default observer(BigAvatar);