import React from 'react';
import module from './centered.module.sass';

const Centered = ({children}) => {
    return (
        <div className={module.centered}>
            {children}
        </div>
    );
};

export default Centered;