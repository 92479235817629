import React from'react';

const useInput = (initial, required) => {
    const [value, setValue] = React.useState(initial);
    const [error, setError] = React.useState(null);

    const onBlur = (e)  =>  {
        if (!e.target.value && required) setError(() => "Required field");
        else setError(() => null);
    }

    const onChange = (e) => {
        setValue(() => e.target.value)
    }

    return {
        value,
        onBlur,
        onChange,
        error
    };
};

export default useInput