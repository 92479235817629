import React from 'react';
import classes from './loader2.module.sass';

const Loader2 = () => {
    return (
        <span className={classes.ldsRing}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </span>
    );
};

export default Loader2;