import React from 'react';
import Wrapper from "../ui/wrapper/wrapper";
import HeaderComponent from "../components/header.component";
import Sider from "../ui/sider/sider";
import Accordion from "../ui/accordion/accordion";
import AccordionItem from "../ui/accordion/accordionItem";
import BACK from "../icons/back.svg";
import EMAIL from "../icons/email.svg";
import PHONE from "../icons/phone.svg";
import {useNavigate} from "react-router-dom";
import Content from "../ui/content/content";
import {Context} from "../index";
import useAuth from "../hooks/useAuth";
import {observer} from "mobx-react-lite";
import T from "../components/t";
import ConfigMethods from "../methods/config.methods";

const SupportPage = () => {
    const {user_store} = React.useContext(Context)
    const {user, loading} = useAuth();
    const navigate = useNavigate();
    const [email, setEmail]  = React.useState("");
    const [phone, setPhone]   = React.useState("");

    React.useEffect(() => {
        if (user) {
            ConfigMethods.getConfig().then(result  =>  {
                setEmail(() => result.data.email);
                setPhone(() => result.data.phone);
                console.log(result.data);
            });
        } else {

        }
    }, [user,  navigate, user_store]);

    return (
        <Wrapper style={{paddingTop: "7rem"}}>
            <Sider>
                <HeaderComponent img={BACK} description={<T>Поддержка</T>} clickAvatar={() => navigate("/")}/>
            </Sider>
            <Content>
                <Accordion variant={"contacts"}>
                    <AccordionItem icon={EMAIL} title={<><p><T>Email</T>:</p> <strong><a href={`mailto:${email}`}>{email}</a></strong></>} />
                    <AccordionItem icon={PHONE} title={<><p><T>Телефон</T>:</p> <strong><a href={`tel:${phone}`}>{phone}</a></strong></>} />
                </Accordion>
            </Content>

        </Wrapper>
    );
};

export default observer(SupportPage);