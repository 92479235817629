import React from 'react';
import module from './avatar.module.sass';
import Typography from "../typography/typography";
import T from "../../components/t";

const Avatar = ({onClick, img, hi, name, description}) => {
    return (
        <div className={module.avatar} onClick={onClick}>
            <div className={module.avatarImg}>
                {
                    img
                        ? <img src={img} alt={'avatar'}/>
                        : <div className={module.avatarImgName}>{name.split(' ')[0].at(0)} {name.split(' ')[1].at(0)}</div>
                }
            </div>
            <Typography variant={'h5'}>
                {
                    hi && <span><T>Привет</T>, </span>
                }
                {description}
            </Typography>
        </div>
    );
};

export default Avatar;