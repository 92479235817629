import AuthPage from "./pages/auth.page";
import MainPage from "./pages/main.page";
import MessagesPage from "./pages/messages.page";
import SupportPage from "./pages/support.page";
import TextPage from "./pages/text.page";
import ProfilePage from "./pages/profile.page";

const routes = [
    {path: '/auth', element: <AuthPage />},
    {path: '/', element: <MainPage />},
    {path: '/messages', element: <MessagesPage />},
    {path: '/support', element: <SupportPage />},
    {path: '/profile', element: <ProfilePage />},
    {path: '/:id', element: <TextPage />},

];

export default routes;