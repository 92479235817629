import {makeAutoObservable} from "mobx";

export default class MessagesStore{
    constructor() {
        this._messages = {};
        makeAutoObservable(this);
    }

    setMessages(data) {this._messages = data}

    get isMessages() {return this._messages}

}