import React from 'react';
import module from './sider.module.sass';

const siderPosition  =  {
    left: "left",
    bottom:  "bottom",
}

const Sider = React.forwardRef(({active, position, children}, ref) => {

    switch (position) {
        case siderPosition.left:
            return (
                <div className={active ? module.sider + ' ' + module.left + ' ' + module.active : module.sider + ' ' + module.left}>
                    <div ref={ref} className={module.content}>
                        {children}
                    </div>
                    <div className={module.wrapper}/>
                </div>
            );
        case siderPosition.bottom:
            return (
                <div ref={ref} className={active ? module.sider  + ' ' + module.bottom + ' ' + module.active : module.sider + ' ' + module.bottom}>
                    {children}
                </div>
            );
        default:
            return (
                <div ref={ref} className={module.sider + ' ' + module.top}>
                    {children}
                </div>
            );
    }
});

export default Sider;