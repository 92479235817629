import module from "./accordion.module.sass";
import React from "react";
import CHEVRON from '../../icons/chevron.svg';

const AccordionItem  =  ({read, icon, title, children, onClick, getData})  => {
    const [active, setActive] = React.useState(false);

    const panelRef = React.useRef(null);
    const toggleAccordion = () => {
        setActive(!active);
    }

    React.useEffect(() => {
        if(active){
            panelRef.current.style.height = panelRef.current.scrollHeight + 'px'
        } else {
            panelRef.current.style.height = null
        }
    }, [active]);

    return (
        <div className={module.item} onClick={getData}>
            <div className={module.header} onClick={onClick ? onClick : toggleAccordion}>
                <div className={module.title}>
                    {
                        icon && <img src={icon} alt="accordion"/>
                    }
                    <div className={read ? module.activeTitle : module.contacts}>{title}</div>
                </div>
                <img className={active ? module.chevron + ' ' + module.active : module.chevron} src={CHEVRON} alt="accordion"/>
            </div>
            <div ref={panelRef} className={active ? module.content + ' ' + module.active  : module.content}>
                <div className={module.data}>{children}</div>
            </div>
        </div>
    );
}

export default AccordionItem;