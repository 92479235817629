import React from 'react';
import module from './button.module.sass';
import POWER from '../../icons/power.svg';
import GATE from '../../icons/gate1.png';
import DOOR from '../../icons/door.svg';
import OPEN from '../../icons/open.svg';
import CLOSE from '../../icons/close.svg';
import Loader2 from "../loader2/loader2";

const buttonVariants = {
    gateRelay: 'gateRelay',
    doorRelay: 'doorRelay',
    room: 'room',
    lang: 'lang',
}


const Button = ({style, active, variant, disabled, onClick, children, open, loader}) => {
    switch  (variant)  {
        case buttonVariants.gateRelay:
            return (
                <div className={module.gateRelay}>
                    <div className={module.gateRelayTitle}>
                        <img src={GATE} alt="gate relay"/>
                        {children}
                    </div>
                    <button disabled={disabled} onClick={onClick}>
                        {
                            loader
                                ?
                                <Loader2/>
                                :
                                <img src={POWER} alt="gate relay"/>

                        }
                    </button>
                </div>
            );
        case buttonVariants.doorRelay:
            return (
                <div className={module.doorRelay}>
                    <div className={module.doorRelayTitle}>
                        <img src={DOOR} alt="gate relay"/>
                        {children}
                    </div>
                    <button disabled={disabled} onClick={onClick}>
                        {

                            loader
                                ?
                                <Loader2/>
                                :
                                open
                                    ?
                                    <img src={OPEN} alt="gate relay"/>
                                    :
                                    <img src={CLOSE} alt="gate relay"/>

                        }
                    </button>
                </div>
            );
        case buttonVariants.lang:
            return (
                <button className={active ? module.buttonLang + ' ' + module.active : module.buttonLang} onClick={onClick} disabled={disabled}>
                    {children}
                </button>
            );
        default:
            return (
                <button style={style} className={module.button} onClick={onClick} disabled={disabled}>
                    {children}
                </button>
            );
    }
};

export default Button;