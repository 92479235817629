import React from 'react';
import module from './alert.module.sass';

const alertVariants = {
    success: 'success',
    warning: 'warning',
    error: 'error',
}

const Alert = ({variant, children}) => {
    switch  (variant)  {
        case alertVariants.success:
            return (
                <div className={`${module.alert}`}>
                    <div className={module.alertSuccess}>
                        {children}
                    </div>
                </div>
            );
        case alertVariants.warning:
            return (
                <div className={`${module.alert}`}>
                    <div className={module.alertWarning}>
                        {children}
                    </div>
                </div>
            );
        case alertVariants.error:
            return (
                <div className={`${module.alert}`}>
                    <div className={module.alertError}>
                        {children}
                    </div>
                </div>
            );
        default:
            return (
                <div className={`${module.alert}`}>
                    <div className={module.alertDefault}>
                        {children}
                    </div>
                </div>
            );
    }

};

export default Alert;