import React from 'react';
import {UserMethods} from "../methods/user.methods";
import {jwtDecode} from "jwt-decode";
import {useNavigate} from "react-router-dom";

const useAuth = () => {
    const navigate  =  useNavigate();
    const [user, setUser] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if (localStorage.getItem('token')) {
            UserMethods.check()
                .then(() => {
                    setUser(() => jwtDecode(localStorage.getItem('token')));
                    if (!jwtDecode(localStorage.getItem('token')).isActive) {
                        setTimeout(()  => {
                            localStorage.removeItem('token');
                            navigate('/auth');
                        }, 10000)
                    }
                })
                .catch((err) => {
                    alert(err.message);
                    localStorage.removeItem('token');
                    setUser(null);
                    navigate('/auth')
                }).finally(() =>  setLoading(() => false));
        } else {
            setUser(null);
            navigate('/auth');
        }
    }, [navigate]);

    return {
        user,
        loading,
        setLoading
    }
};

export default useAuth;