import React from 'react';
import module from './content.module.sass';

const Content = ({style, children}) => {
    return (
        <div style={style} className={module.content}>
            {children}
        </div>
    );
};

export default Content;