import React from 'react';
import module from './wrapper.module.sass';

const Wrapper = ({style, children}) => {
    return (
        <div style={style} className={module.wrapper}>
            {children}
        </div>
    );
};

export default Wrapper;