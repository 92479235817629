import {makeAutoObservable} from "mobx";

export default class UsersStore{
    constructor() {
        this._userRole = "";
        this._messages = {};
        this._isAuth = false;
        this._isError = false;
        this._errorMessage = '';
        this._profile = {};
        this._rooms  = {};
        this._tuya = {}
        this._ajax = {}
        makeAutoObservable(this);
    }

    setTuya(data) {this._tuya = data}

    get isTuya() {return this._tuya}

    setAjax(data) {this._ajax = data}

    get isAjax() {return this._ajax}

    setRooms(data) {this._rooms = data}

    get isRooms() {return this._rooms}

    setProfile(data) {this._profile = data}

    get isProfile() {return this._profile}

    setUserRole(data) {this._userRole = data}

    get isUserRole() {return this._userRole}

    setUserMessages(data) {this._messages = data}

    get isUserMessages() {return this._messages}

    setIsAuth(data) {this._isAuth = data}

    get isAuth() {return this._isAuth}

    setIsError(data) {this._isAError = data}

    get isError() {return this._isError}

    setErrorMessage(data) {this._errorMessage = data}

    get errorMessage() {return this._errorMessage}
}