import React from 'react';
import Wrapper from "../ui/wrapper/wrapper";
import HeaderComponent from "../components/header.component";
import Typography from "../ui/typography/typography";
import Button from "../ui/button/button";
import Sider from "../ui/sider/sider";
import Accordion from "../ui/accordion/accordion";
import AccordionItem from "../ui/accordion/accordionItem";
import ROOM from '../icons/room.svg';

import {useNavigate} from "react-router-dom";
import NotActiveComponent from "../components/notActive.component";
import {Context} from "../index";
import useAuth from "../hooks/useAuth";
import {observer} from "mobx-react-lite";
import Loader from "../ui/loader/loader";
import SiderComponent from "../components/sider.component";
import MessageMethods from "../methods/message.methods";
import {UserMethods} from "../methods/user.methods";
import LocationMethods from "../methods/location.methods";
import DeviceMethods from "../methods/device.methods";
import Group from "../ui/group/group";
import SectorMethods from "../methods/sector.methods";
import Alert from "../ui/alert/alert";
import T from "../components/t";
import StaticMethods from "../methods/static.methods";
import NOTDATA from "../icons/notData.svg";
import PageMethods from "../methods/page.methods";

const MainPage = () => {
    const {user_store, message_store, location_store, static_store} = React.useContext(Context)
    const {user} = useAuth();
    const navigate = useNavigate();
    const [active, setActive]  =  React.useState(false);
    const [loader, setLoader]  =  React.useState(true);
    const [unReadCount, setUnReadCount]  =  React.useState(0);
    const [locations, setLocations] =  React.useState([]);
    const [locationAddress, setLocationAddress] =  React.useState("");
    const [rooms, setRooms]  =  React.useState([]);

    const [devices, setDevices]   =  React.useState([]);
    const [devicesAjax, setDevicesAjax]   =  React.useState([]);
    const [loading, setLoading]   =  React.useState(true);
    const [error, setError]  =  React.useState('');
    const [success, setSuccess]  =  React.useState('');
    const [tuyaStatus, setTuyaStatus]   =  React.useState({});
    const [tuyaStatusArr, setTuyaStatusArr]   =  React.useState([]);
    const [loadingTuya, setLoadingTuya]   =  React.useState(false);
    const [loadingAjax, setLoadingAjax]   =  React.useState(false);
    const [reload, setReload]   =  React.useState(false);
    const [sectors, setSectors]   =  React.useState({});
    const [currentLocation, setCurrentLocation]  =  React.useState([]);
    const [currentDevice, setCurrentDevice]   =  React.useState("");
    const [empty, setEmpty]   =  React.useState(false);
    const [pages, setPages]   =  React.useState([]);

    const [termo, setTermo] = React.useState({});

    React.useEffect(() =>  {
        if (!localStorage.getItem('lang')) {
            localStorage.setItem('lang', 'lv');
        }
    }, [])

    React.useEffect(() =>  {
        static_store.setLoading(true);
        StaticMethods.getStaticByLang(localStorage.getItem('lang')).then((res)  =>  {
            static_store.setStatics(res.data);
        }).finally(() =>  static_store.setLoading(false));

    }, [static_store])

    const changeLocation = (e) => {
        Object.values(location_store.isLocations).map((data)  =>  {
            if (e === data.name)  {
                setLocationAddress(data.address);
                setCurrentLocation()
                const currentSector = [];
                Object.values(sectors).map((sector) =>   {
                    if (sector.location === e)  {
                        if (sector) {
                            currentSector.push(sector);
                        }
                    }
                })
                setCurrentLocation(() => currentSector);
            }
        })
    }

    React.useEffect(() => {
        if (user) {
            user_store.setIsAuth(true);
            const unReadArr = [];
            MessageMethods.getMessages().then((res) => {
                message_store.setMessages(res);
                Object.values(res.data).map((data) => {
                    if (!data.isRead)  {
                        unReadArr.push(data.id);
                    }
                });
            });
            UserMethods.getProfile().then((res) => {
                user_store.setProfile(res.data);
            });
            LocationMethods.getLocations().then((res) => {
                location_store.setLocations(res.data);
                Object.values(res.data).map((data) => {
                    setLocations((prev) => [...prev, {id: data.id, value: data.name, label: data.name}])
                })
            });
            PageMethods.getPages(localStorage.getItem('lang')).then((res)  =>  {
                setPages(res.data);
            })
            UserMethods.getMyRooms(localStorage.getItem('lang')).then((res) => {
                user_store.setRooms(res.data);
                if (Object.values(res.data).length > 0)  {
                    Object.values(res.data).map((data) => {
                        setRooms(
                            (prev) =>
                                [...prev,
                                    {
                                        id: data.id,
                                        value: data.name,
                                        label: data.name,
                                        start_rent: data.startRent,
                                        stop_rent: data.stopRent,
                                        tuya: data.tuya,
                                        sectors: data.sectors,
                                    }
                                ]
                        )
                        DeviceMethods.getTuyaDeviceProperties({device_id: data?.tuya?.at(0)?.deviceId}).then(({result}) => {
                            setTermo(() => result.properties)
                        })

                    });
                } else {
                    setLoading(() => false);
                    setEmpty(() =>  true)
                }

            })
            setUnReadCount(() =>  unReadArr);
            SectorMethods.getSectors().then((res)  =>  {
                setSectors(() => res.data);
            }).finally(() => setLoader(() => false));
        } else {
            user_store.setIsAuth(false);
        }
    }, [user, location_store, message_store, user_store]);

    React.useEffect(()  => {
        Object.values(location_store.isLocations).map((data) => {
            if (locations.length > 0 && locations.at(0).value === data.name) {
                setLocationAddress(data.address);
            }
        })
    }, [location_store, locations]);

    React.useEffect(() => {
        if (rooms.length > 0){
            UserMethods.getMyRooms(localStorage.getItem('lang'))
                .then(res => {
                    if (res){
                        for (const d of Object.values(res.data)) {
                            if (d.tuya) {
                                setDevices((prevState) => [...prevState, ...d.tuya]);
                            }
                        }
                        Object.values(res.data).forEach(item => {
                            if (item.sectors) {
                                for (const sector of item.sectors) {
                                    SectorMethods.getSectorById(sector.id).then((res) => {
                                        if (res && res.data.ajax_devices && res.data.ajax_devices.length > 0) {
                                            for (const d of res.data.ajax_devices) {
                                                DeviceMethods.getDeviceById('ajax', d.id).then((res) => {
                                                    setDevicesAjax((prevState) => [...prevState, res.data]);
                                                })
                                            }
                                        }
                                    })
                                }
                            }
                        });
                    }
                }).finally(() => {
                setLoading(() => false);
            })
        }
    }, [rooms]);

    const openSideBar = () => {
        setActive(() => !active);
    }

    const setCommandDoorTuya = (deviceId, deviceName) => {
        setCurrentDevice(() => deviceId)
        setLoadingTuya(() => true);
        DeviceMethods.getPswTicketTuya({device_id: deviceId}).then((response) => {
            if (response.success) {
                DeviceMethods.getTuyaDeviceStatus({device_id: deviceId}).then((response1) => {
                    DeviceMethods.setCommandOpenDoor(
                        {
                            device_id: deviceId,
                            commands: {ticket_id: response.result.ticket_id, open: !response1.result.status[13].value},
                            userName: user_store.isProfile.first_name + ' ' + user_store.isProfile.last_name,
                            deviceName,
                            command: response1.result.status[13].value ? "close" : "open"
                        })
                        .then((response) => {
                            if (!response1.success) {
                                setError(response.msg);
                            }
                            setSuccess(response1.result.status[13].value ? 'Дверь закрывается' : 'Дверь открывается');
                        })
                        .finally(() =>  {
                            setTimeout(() => {
                                DeviceMethods.getTuyaDeviceStatus({device_id: deviceId}).then((response) => {
                                    if (response.success) {
                                        setReload(() => !reload)
                                    }
                                }).finally(() =>  {
                                    setLoadingTuya(() => false)
                                    setCurrentDevice(() => "")
                                })
                            }, 10000);

                        });
                })
            }
        });
    }

    function sendCommand(device){
        setCurrentDevice(() => device.deviceId)
        setLoadingAjax(() => true)
        const formData = new FormData();
        formData.append("userId", device.ajaxUserId);
        formData.append("hubId", device.hubId);
        formData.append("deviceId", device.deviceId);
        formData.append("deviceType", device.deviceType);
        formData.append("userName", user_store.isProfile.first_name + ' ' + user_store.isProfile.last_name);
        formData.append("deviceName", device.name);
        formData.append("command", "switchStateGate");
        DeviceMethods.setCommand('ajax', formData).then(() => {
            setSuccess('Команда успешно отправлена');
        }).catch((error) => {
            setError(error.response.data.message);
        }).finally(() =>  {
            setLoadingAjax(() => false);
            setCurrentDevice(() => "")
        })
    }

    React.useEffect(() => {
        if (devices.length > 0) {
            for (const d of devices) {
                DeviceMethods.getTuyaDeviceStatus({device_id: d.deviceId}).then((response) => {
                    if (response.success) {
                        setTuyaStatusArr((prev) => [...prev, {devId: d.deviceId, value: response.result.status[13]?.value}]);
                    }
                })
            }
        }
    }, [devices, reload]);

    const res = React.useCallback(()  =>  {
        if (tuyaStatusArr.length > 0)  {
            return tuyaStatusArr.reduce((object, value) => {
                return {...object, [value['devId']]: value}
            }, {});
        }
    }, [tuyaStatusArr]);

    React.useEffect(()  => {
        if (tuyaStatusArr.length > 0)  {
            setTuyaStatus(() => res());
        }
    }, [tuyaStatusArr]);

    React.useEffect(()  =>  {
        if (success.length > 0)  {
            setTimeout(() => {
                setSuccess('');
            }, 3000);
        }
        if (error.length > 0)  {
            setTimeout(() => {
                setError('');
            }, 3000);
        }
    }, [success, error]);


    if (loader || loading || static_store.isLoading) {
        return (
            <div style={{height: '100vh', width: '100vw', display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                <Loader />
            </div>
        )
    }

    return (
        user && !user.isActive
            ?
                <NotActiveComponent/>
            :
                <Wrapper>
                    {
                        error.length > 0
                        &&
                        <Alert variant={"error"}>{error}</Alert>
                    }
                    {
                        success.length > 0
                        &&
                        <Alert variant={"success"}><T>{success}</T></Alert>
                    }
                    <Sider>
                        {/*{*/}
                        {/*    devices.length > 0 &&*/}
                        {/*    <>*/}
                        {/*        <Typography style={{marginTop: '2rem'}} variant={"h4"}>*/}
                        {/*            <T>Двери</T>*/}
                        {/*        </Typography>*/}
                        {/*        {*/}
                        {/*            devices.filter((value, index, array) =>*/}
                        {/*                array.findIndex(item => item.name === value.name) === index*/}
                        {/*            ).map(d =>*/}
                        {/*                <Button*/}
                        {/*                    loader={ d.deviceId === currentDevice*/}
                        {/*                        && loadingTuya}*/}
                        {/*                    disabled={*/}
                        {/*                        d.deviceId === currentDevice*/}
                        {/*                        && loadingTuya*/}
                        {/*                    }*/}
                        {/*                    key={d.id}*/}
                        {/*                    onClick={() => setCommandDoorTuya(d.deviceId, d.name)}*/}
                        {/*                    variant={"doorRelay"}*/}
                        {/*                    open={*/}
                        {/*                        (tuyaStatus[d.deviceId] && d.deviceId === tuyaStatus[d.deviceId].devId)*/}
                        {/*                        && tuyaStatus[d.deviceId].value}*/}
                        {/*                >*/}
                        {/*                    {d.name}*/}
                        {/*                </Button>*/}
                        {/*            )*/}
                        {/*        }*/}
                        {/*    </>*/}

                        {/*}*/}
                        <HeaderComponent termo={termo} name={user_store.isProfile.first_name + " " + user_store.isProfile.last_name} countMessages={unReadCount.length > 0 && unReadCount.length} hi={true} description={user_store.isProfile.first_name} clickAvatar={openSideBar} clickNotification={() => navigate("/messages")}/>
                        {/*{*/}
                        {/*    locations.length > 0*/}
                        {/*    &&*/}
                        {/*        <Select*/}
                        {/*            variant={"text"}*/}
                        {/*            options={locations}*/}
                        {/*            defaultValue={locations.at(0).value}*/}
                        {/*            onRes={changeLocation}*/}
                        {/*        />*/}
                        {/*}*/}
                        {/*{*/}
                        {/*    locationAddress*/}
                        {/*    &&*/}
                        {/*        <Typography style={{*/}
                        {/*            textAlign:  "center",*/}
                        {/*            marginTop: '-.2rem',*/}
                        {/*            overflow: "hidden",*/}
                        {/*            textOverflow: "ellipsis",*/}
                        {/*            whiteSpace: "nowrap"*/}
                        {/*        }}>*/}
                        {/*            {locationAddress}*/}
                        {/*        </Typography>*/}
                        {/*}*/}
                    </Sider>
                    {
                        !empty
                        ?
                            <>

                                {
                                    devicesAjax.length > 0 &&
                                    <>
                                        <Typography style={{marginTop: '1rem'}} variant={"h4"}>
                                            <T>Ворота</T>
                                        </Typography>
                                        {
                                            devicesAjax.filter((a) => Number(a.groups[0]?.value) === 1).filter((value, index, array) =>
                                                array.findIndex(item => item.name.replaceAll(' ', '') === value.name.replaceAll(' ', '')) === index
                                            ).slice().sort((a, b) => a.name.replaceAll(' ', '').at(-1)  > b.name.replaceAll(' ', '').at(-1)  ? 1 : -1).map(d =>
                                                <Button loader={d.deviceId === currentDevice
                                                    && loadingAjax} disabled={d.deviceId === currentDevice
                                                    && loadingAjax} key={d.deviceId} onClick={() => sendCommand(d)} variant={"gateRelay"}>
                                                    {d.name}
                                                </Button>
                                            )
                                        }

                                        <Typography variant={"h4"} style={{marginTop: '2rem'}}>
                                            <T>Двери</T>
                                        </Typography>
                                        {
                                            devicesAjax.filter((a) => Number(a.groups[0]?.value) === 2).filter((value, index, array) =>
                                                array.findIndex(item => item.name.replaceAll(' ', '') === value.name.replaceAll(' ', '')) === index
                                            ).slice().sort((a, b) => a.name.replaceAll(' ', '').at(-1)  > b.name.replaceAll(' ', '').at(-1)  ? 1 : -1).map(d =>
                                                <Button loader={d.deviceId === currentDevice
                                                    && loadingAjax} disabled={d.deviceId === currentDevice
                                                    && loadingAjax} key={d.deviceId} onClick={() => sendCommand(d)} variant={"gateRelay"}>
                                                    {d.name}
                                                </Button>
                                            )
                                        }
                                    </>
                                }

                                {
                                    rooms.length > 0
                                    &&
                                    <>
                                        <Typography style={{marginTop: '2rem'}} variant={"h4"}>
                                            <T>Помещения</T>
                                        </Typography>
                                        <Accordion>
                                            {
                                                rooms.map((data) => {
                                                    return (
                                                        <AccordionItem key={data.id} icon={ROOM} title={data.label}>
                                                            <Group>
                                                                <Typography>
                                                                    <T>Аренда с</T>:
                                                                </Typography>
                                                                <Typography style={{marginTop:  '0'}}>
                                                                    {data.start_rent ? data.start_rent  :  <T>Не назначено</T>}
                                                                </Typography>
                                                            </Group>
                                                            <Group>
                                                                <Typography>
                                                                    <T>Аренда по</T>:
                                                                </Typography>
                                                                <Typography>
                                                                    {data.stop_rent ? data.stop_rent  :  <T>Не назначено</T>}
                                                                </Typography>
                                                            </Group>
                                                        </AccordionItem>
                                                    );
                                                })
                                            }
                                        </Accordion>
                                    </>
                                }
                            </>
                        :
                            <>
                                <div className="notData">
                                    <img src={NOTDATA} alt={"select-icon"}/>
                                    <Typography><T>Нет данных</T></Typography>
                                </div>
                            </>
                    }
                    <SiderComponent active={active} pages={pages} setActive={openSideBar} />
                </Wrapper>
    );
};

export default observer(MainPage);