import {$host} from "../http/axios";

export default class PageMethods {
    static getPages = async (isoCode) => {
        const {data} = await $host.post(`page/get/lang/${isoCode}`);
        return data;
    }

    static getPage = async (id, isoCode) => {
        const {data} = await $host.post(`/page/get/${id}/lang/${isoCode}`);
        return data;
    }
}