import React from 'react';
import Wrapper from "../ui/wrapper/wrapper";
import HeaderComponent from "../components/header.component";
import Sider from "../ui/sider/sider";
import BACK from "../icons/back.svg";
import {useLocation, useNavigate} from "react-router-dom";
import Content from "../ui/content/content";
import {Context} from "../index";
import useAuth from "../hooks/useAuth";
import {observer} from "mobx-react-lite";
import PageMethods from "../methods/page.methods";
import parse from 'html-react-parser';


const MessagesPage = () => {
    const {user_store} = React.useContext(Context);
    const {user, loading} = useAuth();
    const navigate = useNavigate();
    const location  =  useLocation();
    const [title, setTitle]  = React.useState("")
    const [content, setContent]  = React.useState("")

    React.useEffect(()  => {
        PageMethods.getPage(location.pathname.at(-1), localStorage.getItem("lang"))
            .then((res)  =>  {
                console.log(res.data)
                setTitle(() =>  res.data.title)
                setContent(() =>  res.data.content)
                console.log(res.data)
            })
    }, [location])

    React.useEffect(() => {
        if (user) {
            user_store.setIsAuth(true);
        } else {
            user_store.setIsAuth(false);
        }
    }, [user, navigate, user_store]);

    return (
        <Wrapper style={{paddingTop: "8rem"}}>
            <Sider>
                <HeaderComponent img={BACK} description={title}
                                 clickAvatar={() => navigate("/")}/>
            </Sider>
            <Content>
                {
                    content && parse(content)
                }
            </Content>
        </Wrapper>
    );
};

export default observer(MessagesPage);